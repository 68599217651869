import {
  Center,
  Text,
  Text3D,
  useAnimations,
  useGLTF,
} from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { CylinderCollider, RigidBody } from '@react-three/rapier';
import { useEffect, useRef, useState } from 'react';
import { updateShadowsRecursively } from '../utils/meshes';
import useGlobal from '../stores/useGlobal';
import * as THREE from 'three';

const PLAY_SCALE = 0.4;
const HOOP_POSITION = [-15, 6.2, -15];
const HOOP_POSITION_SENSOR = [-15.2, 6.2, -15.2];

export default function Play() {
  const ramp = useGLTF('./ramp.glb');
  const hoop = useGLTF('./hoop.glb');
  const rampRef = useRef();
  const hoopRef = useRef();

  // confetti for when a mesh goes through the hoop
  const start = useGlobal((state) => state.start);
  let lastTriggeredTime = 0;
  const COOLDOWN = 500; // 1 second, adjust as needed

  useEffect(() => {
    updateShadowsRecursively(rampRef.current);
    updateShadowsRecursively(hoopRef.current);
  }, [rampRef.current]);
  return (
    <>
      <RigidBody type='fixed' colliders='trimesh' castShadow>
        <primitive
          object={ramp.scene}
          scale={PLAY_SCALE}
          position-y={-0.1}
          position-x={-5}
          position-z={-5}
          rotation-y={Math.PI * 1.75}
          ref={rampRef}
        />
      </RigidBody>
      <RigidBody type='fixed' colliders='trimesh' castShadow>
        <CylinderCollider
          args={[0.001, 2.5, 0]}
          rotation={[Math.PI * 0.5, 0, Math.PI * 0.75]}
          position={HOOP_POSITION_SENSOR}
          sensor
          onIntersectionExit={(e) => {
            const currentTime = Date.now();
            if (currentTime - lastTriggeredTime > COOLDOWN) {
              start();
              lastTriggeredTime = currentTime;
            }
          }}
        />
        <primitive
          object={hoop.scene}
          scale={PLAY_SCALE}
          position={HOOP_POSITION}
          rotation-y={Math.PI * 0.25}
          ref={hoopRef}
        />
      </RigidBody>
    </>
  );
}
