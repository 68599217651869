'use strict';

/**
 * Recursively turns on shadows for all meshes in the object's hierarchy.
 */
export const updateShadowsRecursively = (object) => {
  if (object.isMesh) {
    object.castShadow = true;
    object.receiveShadow = true;
  }

  if (object.children && object.children.length > 0) {
    object.children.forEach((child) => {
      updateShadowsRecursively(child);
    });
  }
};
