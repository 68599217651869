import { useGLTF } from '@react-three/drei';
import { RigidBody } from '@react-three/rapier';
import { useEffect, useRef, useState } from 'react';
import { updateShadowsRecursively } from '../utils/meshes';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const ISLAND_SCALE = 0.5;
const HIGHLIGHTED_ISLAND_SCALE = 0.52;
const SCALE_LERP = 0.0005;

export default function Socials() {
  const github = useGLTF('./github.glb');
  const linkedin = useGLTF('./linkedin.glb');
  const githubRef = useRef();
  const linkedinRef = useRef();
  const [githubSize, setGithubSize] = useState('normal');
  const [linkedinSize, setLinkedinSize] = useState('normal');

  useEffect(() => {
    updateShadowsRecursively(githubRef.current);

    linkedinRef.current.children.forEach((child, i) => {
      if (i === 0) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
  }, [githubRef.current]);

  useFrame(() => {
    const githubScale = githubRef.current.scale.x;
    const linkedinScale = linkedinRef.current.scale.x;

    if (githubSize === 'large' && githubScale < HIGHLIGHTED_ISLAND_SCALE) {
      const newGithubScale = new THREE.Vector3();
      newGithubScale.copy(githubRef.current.scale);
      newGithubScale.addScalar(SCALE_LERP);
      githubRef.current.scale.setScalar(newGithubScale.x);
    } else if (githubScale > ISLAND_SCALE) {
      const newGithubScale = new THREE.Vector3();
      newGithubScale.copy(githubRef.current.scale);
      newGithubScale.subScalar(SCALE_LERP);
      githubRef.current.scale.setScalar(newGithubScale.x);
    }
    if (linkedinSize === 'large' && linkedinScale < HIGHLIGHTED_ISLAND_SCALE) {
      const newLinkedinScale = new THREE.Vector3();
      newLinkedinScale.copy(linkedinRef.current.scale);
      newLinkedinScale.addScalar(SCALE_LERP);
      linkedinRef.current.scale.setScalar(newLinkedinScale.x);
    } else if (linkedinScale > ISLAND_SCALE) {
      const newLinkedinScale = new THREE.Vector3();
      newLinkedinScale.copy(linkedinRef.current.scale);
      newLinkedinScale.subScalar(SCALE_LERP);
      linkedinRef.current.scale.setScalar(newLinkedinScale.x);
    }
  });

  const increase = (e) => {
    document.body.style.cursor = 'pointer';
    if (e.eventObject.name === 'githubble') {
      setGithubSize('large');
    } else if (e.eventObject.name === 'linkedydinkety') {
      setLinkedinSize('large');
    }
  };

  const decrease = (e) => {
    document.body.style.cursor = 'auto';
    if (e.eventObject.name === 'githubble') {
      setGithubSize('normal');
    } else if (e.eventObject.name === 'linkedydinkety') {
      setLinkedinSize('normal');
    }
  };

  const clicked = (e) => {
    if (e.eventObject.name === 'githubble') {
      window.open('https://github.com/otaeotay');
    } else if (e.eventObject.name === 'linkedydinkety') {
      window.open('https://www.linkedin.com/in/tae-hwan-lee/');
    }
  };

  return (
    <>
      <RigidBody type='fixed' colliders='trimesh' castShadow>
        <primitive
          object={github.scene}
          scale={ISLAND_SCALE}
          position-y={-0.05}
          position-x={-17.5}
          position-z={16}
          rotation-y={Math.PI * 0.6}
          rotation-z={Math.PI * 0.2}
          ref={githubRef}
          onPointerEnter={increase}
          onPointerLeave={decrease}
          name='githubble'
          onClick={clicked}
          //   rotation-z={-Math.PI * 0.006}
        />
      </RigidBody>
      <RigidBody type='fixed' colliders='trimesh' castShadow>
        <primitive
          object={linkedin.scene}
          scale={ISLAND_SCALE}
          position-y={-0.05}
          position-x={-15}
          position-z={15}
          rotation-y={Math.PI * 0.6}
          rotation-z={Math.PI * 0.2}
          ref={linkedinRef}
          onPointerEnter={increase}
          onPointerLeave={decrease}
          name='linkedydinkety'
          onClick={clicked}
          //   rotation-z={-Math.PI * 0.006}
        />
      </RigidBody>
    </>
  );
}
